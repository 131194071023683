@import '~antd/dist/antd.css';

.App {
  text-align: center;
}
.header {
  background : #001529;
}

.login-wrapper {
  width: 500px;
  height: 130px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  margin: 100px auto;
}

.search-wrapper {
  text-align: center;
}

.search-wrapper > h1{
  margin-top: 30px;
}
.sticker-view-wrapper {
  max-width: 1200px;
  margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
}
.sticker-view {
  border: 1px solid #ddd;
  margin: 10px;
}
.search-input {
  width: 500px;
  margin: 20px;
}

.search-select {
  width: 120px;
  margin: 20px 5px;
}